import React, { useState, useEffect } from 'react';
import './App.css';
import generatePassword from './generator';
import 'bulma/css/bulma.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import copy from 'copy-to-clipboard';
import { Checkbox } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [length, setLength] = useState(8);
  const [capitalLetters, setCapitalLetters] = useState(true);
  const [smallLetters, setSmallLetters] = useState(true);
  const [numbers, setNumbers] = useState(true);
  const [specialChars, setSpecialChars] = useState(false);
  const [password, setPassword] = useState("");

  const handleGenerate = () => {
    setPassword(generatePassword(length, capitalLetters, smallLetters, numbers, specialChars));
  };

  const copyToClipboard = () => {
    copy(password);
    toast.info("Hasło zostało skopiowane do schowka");
  };

  useEffect(() => {
    handleGenerate();
  }, [length, capitalLetters, smallLetters, numbers, specialChars]);

  return (
    <div className="App">
      <button className="password" onClick={() => copyToClipboard()}>
        <p className="title" id="password-text">{password}</p>
      </button>
      <ToastContainer />
      <div className="slider-container" style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: "1rem"}}>
        <p style={{color: 'white', paddingRight: 10}}>Długość:</p>
        <input className="slider" type="range" min={5} max={128} step={1} value={length} onChange={(e) => setLength(parseInt(e.target.value))} />
        <p style={{color: 'white', paddingLeft: 4}}>{length}</p>
      </div>
      <div className="checkbox-container">
        <div className="checkboxes">
          <Checkbox style={{color: 'white', marginTop: '1rem'}} defaultChecked checked={capitalLetters} onChange={() => setCapitalLetters(!capitalLetters)}> Duże litery</Checkbox>
          <Checkbox style={{color: 'white', marginTop: '1rem'}} defaultChecked checked={smallLetters} onChange={() => setSmallLetters(!smallLetters)}> Małe litery</Checkbox>
          <Checkbox style={{color: 'white', marginTop: '1rem'}} defaultChecked checked={numbers} onChange={() => setNumbers(!numbers)}> Numery</Checkbox>
          <Checkbox style={{color: 'white', marginTop: '1rem'}} checked={specialChars} onChange={() => setSpecialChars(!specialChars)}> Znaki specjalne</Checkbox>
        </div>
      </div>
      <button className="button is-medium is-rounded is-success" style={{marginTop: '2rem'}} onClick={() => handleGenerate()}>
        <span className="icon">
          <FontAwesomeIcon icon={faCogs} />
        </span>
        <span>Wygeneruj</span>
      </button>
    </div>
  );
}

export default App;
