const generatePassword = (length: number, capital: boolean, small: boolean, nums: boolean, special: boolean) => {
  const capitalLetters = "ABCDEFGHIJKLMNOPRSTUVXYZ";
  const smallLetters = "abcdefghijklmnoprstuvxyz";
  const numbers = "0123456789";
  const specialChars = `/?.>,<'";:]}[{=+-_)(*&^%$#@!`;
  let list: string = "";
  if (capital) {
    list += capitalLetters;
  }
  if (small) {
    list += smallLetters;
  }
  if (nums) {
    list += numbers;
  }
  if (special) {
    list += specialChars;
  }

  let pass: string = "";

  for (let i = 0; i < length; i++) {
    pass += list.charAt(Math.random() * list.length) 
  };
  return pass;
};

export default generatePassword;
